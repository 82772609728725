import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/owenbean/ems/ems_web/src/components/ApiDocumentationLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Get Devices`}</h1>
    <h2>{`GET `}<inlineCode parentName="h2">{`/v1/ems/device`}</inlineCode></h2>
    <p>{`Gets a list of devices of user through paging. Pagination allow for smaller set of data to be sent over through the api that helps prevent huge data transfer over the network.`}</p>
    <h3>{`Request`}</h3>
    <h4>{`Headers`}</h4>
    <pre><code parentName="pre" {...{}}>{`"Authorization": "Bearer <Token>"
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Query Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pageNumber`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The page number of fetching device records. Must be an integer greater than 0. Default value to first page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pageSize`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of items in the list fetched from a page. Must be an integer between 1 and 20 inclusively. Default value is 5.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Response `}<inlineCode parentName="h3">{`200`}</inlineCode></h3>
    <p>{`The devices has been successfully fetched.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "pageSize": [number] The page size of the content,
    "pageNumber": [number] The page number of the content,
    "content": [
        {
            "id": [string] ID of record,
            "device_name": [string] Device name,
            "device_id": [string] Generated device ID,
            "os": [string] Device OS System
        },
        ...
    ]
}
`}</code></pre>
    <h3>{`Response `}<inlineCode parentName="h3">{`400`}</inlineCode></h3>
    <p>{`There was an issue with the query parameters.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "error": [string] error,
    "message": [string] message about the error
}
`}</code></pre>
    <h4>{`Messages`}</h4>
    <ul>
      <li parentName="ul">{`pageNumber must be an int that is greater than 0.`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`The pageNumber query was not a whole number that is greater than 0.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`pageSize must be an int that is greater than 0 and less than or equal to 20.`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`The pageSize query was not a whole number that is greater than 0 and less than or equal to 20.`}</p>
        </blockquote>
      </li>
    </ul>
    <h3>{`Response `}<inlineCode parentName="h3">{`401`}</inlineCode></h3>
    <p>{`Authorization issue.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "Error": "Unauthorized",
    "message": [string] Authorization issue
}
`}</code></pre>
    <h4>{`Messages`}</h4>
    <ul>
      <li parentName="ul">{`You are not authorized to access this resource`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`The token from Firebase is invalid`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Authorization header is missing`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`There is no "Authorization" in the header request.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Invalid authorization header format`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`The "Authorization" header does not have "Bearer Token" format.`}</p>
        </blockquote>
      </li>
    </ul>
    <h3>{`Response `}<inlineCode parentName="h3">{`500`}</inlineCode></h3>
    <p>{`Internal error with the server.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "error": "Internal Server Error",
    "message": "Internal Server Error"
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      